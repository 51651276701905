import React from 'react';
import AppV1 from './features/v1';
import './App.scss';

function App() {
  return (
    <AppV1></AppV1>
  );
}

export default App;
